<template>
  <!-- 库存详情 -->
  <div class="hangOutDetails page-info-content">
    <!-- 详情展示 -->
    <!-- <el-tabs>
      <el-tab-pane label="业务信息"> -->
    <Details-page :details-title="'申请企业信息'" :detail-obj="detailCreditObj.financialInfo" :details-item-arr="detailsItemArr" />
    <!-- 审核选择  通过还是驳回  如果是审核的话这里会出现 -->
    <el-form
      v-if="showDetail"
      ref="ruleForm"
      :model="ruleForm"
      :inline="true"
      label-width="140px"
      class="demo-ruleForm"
      label-position="left"
    >
      <h3 class="page-subtitle-shade settlementTitle">
        &nbsp;审核意见
      </h3>
      <el-form-item v-if="!adjustment" prop="custCmpName" label="审核结果">
        <el-radio-group v-model="ruleForm.audit" @change="$forceUpdate()">
          <el-radio :label="true">
            通过
          </el-radio>
          <el-radio :label="false">
            驳回
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <h3 v-if="detailCreditObj.creditStatus !== 1&&!adjustment&&ruleForm.audit" class="page-subtitle-shade settlementTitle">
        &nbsp;授信配置
      </h3>
      <!-- 这里是审核通过了才有的   而且业务员的话看不到这里   只有风控人员和业务负责人才能看到的 -->
      <div v-if="ruleForm.audit && hiddenSalesman">
        <div class="form-item-row">
          <el-form-item prop="creditTotalAmtTwo" :rules="rules.price" label="授信额度(万元)">
            <el-input v-model="ruleForm.creditTotalAmtTwo" type="number" placeholder="请输入授信额度" @input="$forceUpdate()" />
          </el-form-item>
          <el-form-item prop="validDate" :rules="rules.date" label="额度到期日">
            <el-date-picker v-model="ruleForm.validDate" :picker-options="pickerOptions" value-format="yyyy-MM-dd" type="date" placeholder="额度到期日" />
          </el-form-item>
          <el-form-item prop="depositRate" :rules="rules.limitedNum" label="保证金比例(%)">
            <el-input
              v-model="ruleForm.depositRate"
              type="number"
              placeholder="请输入保证金比例"
              :disabled="adjustment"
            />
          </el-form-item>
          <el-form-item prop="depostDeductPayment" :rules="rules.selectiveType" label="保证金抵扣货款">
            <el-radio-group v-model="ruleForm.depostDeductPayment" :disabled="adjustment">
              <el-radio :label="1">
                是
              </el-radio>
              <el-radio :label="0">
                否
              </el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item v-if="!adjustment" prop="transactionType" :rules="rules.transactionType" label="交易类型">
            <el-checkbox v-model="ruleForm.lockQuantity" label="锁量" :disabled="adjustment" />
            <el-checkbox v-model="ruleForm.lockPrice" label="锁价" :disabled="adjustment" />
          </el-form-item>
          <el-form-item v-if="!adjustment" prop="proxyContractTemplateId" :rules="rules.selectiveType" label="代理采购协议">
            <el-select
              v-model="ruleForm.proxyContractTemplateId"
              placeholder="请选择代理采购协议"
              :disabled="adjustment"
              @change="getContractTemplateId"
            >
              <el-option
                v-for="item in contractTemplateList"
                :key="item.compactId"
                :label="item.compactName"
                :value="item.compactId"
              />
            </el-select>
          </el-form-item>
          <el-form-item v-if="!adjustment" :rules="rules.days" prop="maxPurchaseDays" label="采购期限(天)">
            <el-input
              v-model="ruleForm.maxPurchaseDays"
              type="number"
              placeholder="请输入采购期限"
              :disabled="adjustment"
            />
          </el-form-item>
          <!-- 阶梯计费这个是没有的 -->
          <el-form-item
            v-if="!adjustment"
            :rules="rules.days2"
            prop="deferredPaymentDays"
            label="货款延期支付天数"
          >
            <div class="buyingExpenses">
              <el-input
                v-model="ruleForm.deferredPaymentDays"
                type="number"
                placeholder="请输入货款延期支付天数"
                :disabled="adjustment"
              />
              <span>天</span>
            </div>
          </el-form-item>
          <el-form-item prop="overdueInterest" :rules="rules.limitedThreeNum" label="逾期费率">
            <div class="buyingExpenses">
              <el-input
                v-model="ruleForm.overdueInterest"
                :rules="rules.name"
                type="number"
                placeholder="请输入逾期费率"
                :disabled="adjustment"
              />
              <span>%/天</span>
            </div>
          </el-form-item>
        </div>
        <!-- 周期计费这个是没有的 -->
        <el-form-item v-if="ruleForm.rateType === 2 && !adjustment" label="采购阶梯费用" class="lastOne table-overspread">
          <div class="list-operation">
            <el-button size="small" type="primary" icon="el-icon-plus" @click="addGoodsConfig" />
          </div>
          <table class="table">
            <tr>
              <td>价格金额</td>
              <td>基数(元/吨)</td>
              <td>规则</td>
              <td>操作</td>
            </tr>
            <tr v-for="(item, inde) in ruleForm.agentRateLadderConfigList" :key="inde">
              <td>
                <el-input-number
                  v-model="item.lowerLimit"
                  :precision="2"
                  :controls="false"
                  class="intervalValue"
                  placeholder="金额下限"
                  @change="getLowerLimit(item, inde)"
                />
                <el-input-number
                  v-model="item.upperLimit"
                  :precision="2"
                  :controls="false"
                  class="intervalValue"
                  placeholder="金额上限"
                  @change="getUpperLimit(item, inde)"
                />
              </td>
              <td>
                <el-input v-model="item.base" type="number" placeholder="请输入基数" />
              </td>
              <td>
                <el-select v-model="item.operFormula" placeholder="请选择费率类型">
                  <el-option label="基数*单价/阶梯价" value="基数*单价/阶梯价" />
                </el-select>
              </td>
              <td style="width:48px">
                <i
                  class="icons el-icon-remove-outline"
                  @click="ruleForm.agentRateLadderConfigList.splice(inde, 1)"
                />
              </td>
            </tr>
            <tr>
              <td v-if="ruleForm.agentRateLadderConfigList.length === 0" colspan="6">
                没有数据，请添加！
              </td>
            </tr>
          </table>
        </el-form-item>
        <!-- 代采的呀下面的就必须要填  阶梯计费这些都没有的   -->
        <div v-if="!adjustment">
          <h3 class="page-subtitle-shade settlementTitle">
            &nbsp;计息方式
          </h3>
          <div v-for="(item,index) in ruleForm.agentInterestSchsArr" :key="index" class="form-item-row specific-item">
            <el-form-item label="" style="width:130px">
              <el-checkbox v-model="item.isChecked" :label="item.name" @change="changeIsChecked(item,index)" />
            </el-form-item>
            <el-form-item :prop="'agentInterestSchsArr.' + index " :rules="rules.agentInterestSch" label="采购费率" class="input-unit">
              <div class="buyingExpenses different-box">
                <el-input v-model="item.rateValue" :disabled="!item.isChecked" type="number" placeholder="请输入采购费率" @input="activeIndex = index" />
                <el-select v-if="item.child" v-model="item.rateCycle" :disabled="!item.isChecked" placeholder="请选择" @change="activeIndex=index;$forceUpdate()">
                  <el-option v-for="(val,ind) in item.child" :key="ind" :label="val.name" :value="val.value" />
                </el-select>
                <span class="unit">{{ item.unit }}</span>
              </div>
            </el-form-item>
          </div>
        </div>
        <!-- 集采的呀 -->
        <!-- <div v-if="!adjustment">
          <el-form-item prop="authAmount" label="是否可以集采">
            <el-checkbox
              v-model="ruleForm.inConfiguration"
              label="勾选填写配置"
              :disabled="adjustment"
              @change="$forceUpdate()"
            />
          </el-form-item>
          <div class="form-item-row">
            <el-form-item
              v-if="ruleForm.inConfiguration"
              :rules="rules.centralInterestSch"
              prop="authAmount"
              label="集采费率"
            >
              <div class="buyingExpenses">
                <el-input-number
                  v-model="ruleForm.centralInterestSch.rateValue"
                  :precision="3"
                  :controls="false"
                  placeholder="请输入集采费率"
                  :disabled="adjustment"
                />
                <el-select
                  v-model="ruleForm.centralInterestSch.rateUnit"
                  class="interval"
                  placeholder="请选择"
                  :disabled="adjustment"
                  @change="ruleForm.centralInterestSch.rateCycle = null"
                >
                  <el-option label="%" :value="1" />
                  <el-option label="元/吨" :value="2" />
                </el-select>
                <el-select
                  v-if="ruleForm.centralInterestSch.rateUnit === 1"
                  v-model="ruleForm.centralInterestSch.rateCycle"
                  placeholder="请选择"
                  :disabled="adjustment"
                  @change="$forceUpdate()"
                >
                  <el-option label="天" :value="1" />
                  <el-option label="周" :value="2" />
                <el-option label="月" :value="3" />
                  <el-option label="年" :value="4" />
                </el-select>
                <el-select
                  v-if="ruleForm.centralInterestSch.rateUnit === 2"
                  v-model="ruleForm.centralInterestSch.rateCycle"
                  placeholder="请选择"
                  :disabled="adjustment"
                  @change="$forceUpdate()"
                >
                  <el-option label="天" :value="1" />
                  <el-option label="周" :value="2" />
                <el-option label="月" :value="3" />
                <el-option label="年" :value="4" />
                </el-select>
              </div>
            </el-form-item>
          </div>
        </div> -->
      </div>
      <!-- 审核不通过的 -->
      <el-form-item v-if="!ruleForm.audit" class="maxWidth" :rules="rules.message" prop="rejectionReasons" label="驳回原因">
        <el-input
          v-model="ruleForm.rejectionReasons"
          type="textarea"
          :rows="3"
          maxlength="50"
          show-word-limit
          placeholder="请输入驳回原因"
          :disabled="adjustment"
        />
      </el-form-item>
    </el-form>
    <!-- 如果只是详情的话   这里会出现 -->
    <div v-else-if="detailCreditObj.creditStatus !== 1&& Number(detailCreditObj.creditTotalAmtTwo)>0" class="detailsBox">
      <Details-page
        :table-title="detailCreditObj.agentRateLadderConfigList && detailCreditObj.agentRateLadderConfigList.length > 0 ? '采购阶梯费用' : ''"
        :item-data="detailCreditObj.agentRateLadderConfigList && detailCreditObj.agentRateLadderConfigList.length > 0 ? itemData : []"
        :list-data="detailCreditObj.agentRateLadderConfigList || []"
        :details-title="'授信方案'"
        :detail-obj="detailCreditObj"
        :details-item-arr="detailsCreditItemArr"
      >
        <template>
          <el-descriptions :content-style="{'font-size':'14px'}" :label-style="{'font-size':'14px'}" title="计息方式">
            <el-descriptions-item v-if="detailCreditObj.interestAccrual1" label="按比率计息">
              {{ detailCreditObj.interestAccrual1 }}
            </el-descriptions-item>
            <el-descriptions-item v-if="detailCreditObj.interestAccrual2" label="周期加价">
              {{ detailCreditObj.interestAccrual2 }}
            </el-descriptions-item>
            <el-descriptions-item v-if="detailCreditObj.interestAccrual3" label="固定加价">
              {{ detailCreditObj.interestAccrual3 }}
            </el-descriptions-item>
          </el-descriptions>
        </template>
      </Details-page>
    </div>
    <!-- </el-tab-pane>
    </el-tabs> -->
    <div class="form-operation">
      <el-button v-if="$route.query.type === 'adjustment' || ( showDetail && !$route.query.type)" size="small" plain class="widen-button" @click="$router.go(-1)">
        取消
      </el-button>
      <el-button v-if="showDetail && !$route.query.type" :loading="$store.state.loading" size="small" type="primary" class="widen-button" @click="determineAudit">
        确定审核
      </el-button>
      <el-button v-if="$route.query.type === 'adjustment'" size="small" :loading="$store.state.loading" type="info" class="widen-button" @click="limitAdjustment">
        额度调整
      </el-button>
    </div>
  </div>
</template>

<script>
import { agentCreditInfoOperateUpdate, agentFinancialInfoDetails, agentCreditInfoAdjustmentQuota } from '@/http/agentApi/crmManagement.js'
import { compactServiceGetCompactByType } from '@/http/agentApi/enterpriseInformation'
import { rules, funNonnegativeNumber, funThreeDecimalPlaces } from '@/unit/matchFormRegex'
import { timestampToTime, roundUpToInteger } from '@/unit/index'
import DetailsPage from '@/components/DetailsPage.vue'
export default {
  components: { DetailsPage },
  data() {
    var validate1 = (rule, value, callback) => {
      const currentObj = { ...value } || {}
      if (currentObj.isChecked && currentObj.rateValue && funNonnegativeNumber(currentObj.rateValue) === false) {
        callback(new Error('请输入非负数'))
        return
      }
      if (currentObj.isChecked && currentObj.rateValue && funThreeDecimalPlaces(currentObj.rateValue) === false) {
        callback(new Error('小数点后最多三位'))
        return
      }
      if (currentObj.isChecked && !currentObj.rateValue) {
        callback(new Error('请输入采购费率'))
        return
      }
      if (currentObj.isChecked && !currentObj.rateCycle && currentObj.rateUnit !== 3) {
        callback(new Error('请选择计息单位'))
        return
      }
      if (currentObj.isChecked && currentObj.rateUnit === 1 && parseInt(currentObj.rateValue) >= 100) {
        callback(new Error('当选择%时，数值不能超过等于100'))
        return
      }
      callback()
    }
    var transactionType = (rule, value, callback) => {
      if (!this.ruleForm.lockQuantity && !this.ruleForm.lockPrice) {
        callback(new Error('交易类型最少选择一个'))
        return
      }
      callback()
    }
    return {
      activePage: 0,
      hiddenSalesman: true,
      activeIndex: 0,
      rules: {
        ...rules,
        transactionType: [{ required: true, validator: transactionType, trigger: ['blur', 'change'] }],
        agentInterestSch: [{ required: true, validator: validate1, trigger: ['blur', 'change'] }]
      },
      detailsItemArr: [
        { label: '公司名称', value: 'cmpName' },
        { label: '统一社会信用代码', value: 'cmpUnicode' },
        { label: '公司地址', value: 'currAddr' },
        { imageSrc: 'businessLicenseNamePath', label: '营业执照' },
        { imageSrc: 'leadIdcardFrontNamePath', label: '法人身份证正面' },
        { imageSrc: 'leadIdcardBackNamePath', label: '法人身份证反面' },
        { link: 'cmpCreditReportNamePath', label: '企业征信报告', value: 'cmpCreditReportName' },
        { link: 'financialReportsNamePath', label: '财务报表', value: 'financialReportsName' },
        { link: 'balanceSheetNamePath', label: '资产负债表', value: 'balanceSheetName' },
        { link: 'rulesNamePath', label: '公司章程', value: 'rulesName' },
        { link: 'taxReturnNamePath', label: '纳税申报表', value: 'taxReturnName' },
        { link: 'flowMeterNamePath', label: '流水表', value: 'flowMeterName' },
        { link: 'topFiveTransacNamePath', label: '上下游前五交易报表', value: 'topFiveTransacName' },
        { link: 'othAccessNamePath', label: '其他附件', value: 'othAccessName' },
        // { label: '详细地址', value: 'detailAddr' },
        { label: '联系人', value: 'contacts' },
        { label: '联系电话', value: 'contactsPhone' },
        { label: '状态', value: 'creditStatus', child: this.$store.getters.getDictionaryItem('CREDIT_STATUS'), whetherShown: true },
        { label: '驳回原因', value: 'rejectionReasons', whetherShown: true }
      ],
      detailsCreditItemArr: [
        // { label: '费率类型', value: 'rateType', child: [{ id: 1, val: '周期计息' }, { id: 2, val: '阶梯计息' }] },
        { label: '授信额度(万元)', money: 'creditTotalAmtTwo' },
        { label: '保证金比例(%)', value: 'depositRate' },
        { label: '额度到期日', value: 'validDate' },
        { label: '保证金是否抵扣货款', value: 'depostDeductPayment', child: [{ id: '0', val: '否' }, { id: 1, val: '是' }] },
        { label: '交易类型', value: 'tradeType' },
        { download: 'proxyContractUrl', label: '代理采购协议', value: 'proxyContractTemplateName', whetherShown: true },
        { label: '采购期限(天)', value: 'maxPurchaseDays' },
        { label: '货款延期支付天数(天)', value: 'deferredPaymentDays' },
        { label: '逾期费率', value: 'overdueInterest' }

      ],
      itemData: [
        { label: '金额小值', prop: 'lowerLimit' },
        { label: '金额大值', prop: 'upperLimit' },
        { label: '基数(元/吨)', prop: 'base' },
        { label: '梯度费率计算公式', prop: 'operFormula' }
      ],
      detailCreditObj: {},
      showDetail: true,
      ruleForm: {
        creditTotalAmtTwo: 0,
        agentInterestSchsArr: [{ name: '按比例计息', unit: '%', rateUnit: 1, child: [{ name: '天', value: 1 }, { name: '年', value: 4 }] }, { name: '周期加价', rateUnit: 2, unit: '元/吨', child: [{ name: '天', value: 1 }, { name: '周', value: 2 }] }, { name: '固定加价', rateUnit: 3, unit: '元/吨' }],
        agentRateLadderConfigList: [],
        agentInterestSch: {},
        centralInterestSch: {}
      },
      contractTemplateList: [], // 合同模板列表
      adjustment: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 86400000
        }
      }
    }
  },
  mounted() {
    // 详情接口分为两个了
    if (this.$route.query.id) {
      agentFinancialInfoDetails(this.$route.query.id, res => {
        // 如果是详情的话   就把状态跟审核原因移到这个里面去
        if (!this.showDetail && res.data.financialInfo) {
          res.data.financialInfo.creditStatus = res.data.creditStatus + ''
          res.data.financialInfo.rejectionReasons = res.data.rejectionReasons
        }
        // 计息方式根据数据渲染出来
        if (res.data.agentInterestSchs && res.data.agentInterestSchs.length > 0) {
          let flag1 = false; let flag2 = false; let flag3 = false
          res.data.agentInterestSchsArr = [...res.data.agentInterestSchs]
          res.data.agentInterestSchsArr.forEach(item => {
            switch (item.rateUnit) {
              case 1:
                flag1 = true
                item.name = '按比例计息'
                item.unit = '%'
                if (item.rateValue) {
                  res.data.interestAccrual1 = `采购费率${item.rateValue}%/${item.rateCycle === 1 ? '天' : '年'}`
                  item.isChecked = true
                }
                item.child = [{ name: '天', value: 1 }, { name: '年', value: 4 }]
                break
              case 2:
                flag2 = true
                item.name = '周期加价'
                item.unit = '元/吨'
                if (item.rateValue) {
                  res.data.interestAccrual2 = `采购费率${item.rateValue}元/吨/${item.rateCycle === 1 ? '天' : '周'}`
                  item.isChecked = true
                }
                item.child = [{ name: '天', value: 1 }, { name: '周', value: 2 }]
                break
              case 3:
                flag3 = true
                item.name = '固定加价'
                item.unit = '元/吨'
                if (item.rateValue) {
                  res.data.interestAccrual3 = `采购费率${item.rateValue}元/吨`
                  item.isChecked = true
                }
                break
            }
          })
          if (!flag1) {
            res.data.agentInterestSchsArr.push({ name: '按比例计息', unit: '%', rateUnit: 1, child: [{ name: '天', value: 1 }, { name: '年', value: 4 }] })
          }
          if (!flag2) {
            res.data.agentInterestSchsArr.push({ name: '周期加价', rateUnit: 2, unit: '元/吨', child: [{ name: '天', value: 1 }, { name: '周', value: 2 }] })
          }
          if (!flag3) {
            res.data.agentInterestSchsArr.push({ name: '固定加价', rateUnit: 3, unit: '元/吨' })
          }
        } else {
          res.data.agentInterestSchsArr = [{ name: '按比例计息', unit: '%', rateUnit: 1, child: [{ name: '天', value: 1 }, { name: '年', value: 4 }] }, { name: '周期加价', rateUnit: 2, unit: '元/吨', child: [{ name: '天', value: 1 }, { name: '周', value: 2 }] }, { name: '固定加价', rateUnit: 3, unit: '元/吨' }]
        }
        // 金额除以10000
        if (res.data.creditTotalAmt)res.data.creditTotalAmtTwo = res.data.creditTotalAmt === 0 ? '' : roundUpToInteger(Number(res.data.creditTotalAmt) / 10000)
        this.detailCreditObj = { ...res.data }
        if (this.detailCreditObj.overdueInterest) this.detailCreditObj.overdueInterest = this.detailCreditObj.overdueInterest + '%/天'
        // 判断是不是业务员审核   为1的时候就不显示那些填写的配置
        switch (this.detailCreditObj.creditStatus) {
          case 1:
            this.hiddenSalesman = false
            break
        }
        // 给要审核的数据重新赋值
        this.ruleForm = { ...res.data }
        this.ruleForm.rateType = 1
        this.ruleForm.creditId = this.$route.query.id
        this.ruleForm.audit = true
        this.ruleForm.lockPrice = this.ruleForm.lockPrice === 1
        if (this.ruleForm.deferredPaymentDays) this.ruleForm.deferredPaymentDays = this.ruleForm.deferredPaymentDays + ''
        if (this.ruleForm.maxPurchaseDays) this.ruleForm.maxPurchaseDays = this.ruleForm.maxPurchaseDays + ''
        this.ruleForm.lockQuantity = this.ruleForm.lockQuantity === 1
        if (this.ruleForm.validDate) this.ruleForm.validDate = timestampToTime(this.ruleForm.validDate)
        this.ruleForm.inConfiguration = !!this.ruleForm.centralInterestSchid
        this.ruleForm.generationConfiguration = !!this.ruleForm.agentInterestSchid
        // 因为没有数据后端返回的是null  所以要判断一下
        this.ruleForm.agentInterestSch = this.ruleForm.agentInterestSch ? { ...this.ruleForm.agentInterestSch } : {}
        this.ruleForm.centralInterestSch = this.ruleForm.centralInterestSch ? { ...this.ruleForm.centralInterestSch } : {}
        this.ruleForm.agentRateLadderConfigList = this.ruleForm.agentRateLadderConfigList ? [...this.ruleForm.agentRateLadderConfigList] : []
        // 代采采购费用
        if (this.detailCreditObj.agentInterestSch) {
          this.detailCreditObj.procurementCosts = this.detailCreditObj.agentInterestSch.rateValue
          switch (this.detailCreditObj.agentInterestSch.rateUnit) {
            case 1:
              this.detailCreditObj.procurementCosts += '%'
              break
            case 2:
              this.detailCreditObj.procurementCosts += '元/吨'
              break
            case 3:
              this.detailCreditObj.procurementCosts += '元/吨'
              break
          }
          switch (this.detailCreditObj.agentInterestSch.rateCycle) {
            case 1:
              this.detailCreditObj.procurementCosts += '/天'
              break
            case 2:
              this.detailCreditObj.procurementCosts += '/周'
              break
            case 3:
              this.detailCreditObj.procurementCosts += '/月'
              break
            case 4:
              this.detailCreditObj.procurementCosts += '/年'
              break
          }
        }
        // 交易类型
        this.detailCreditObj.tradeType = (this.detailCreditObj.lockPrice === 1 ? '锁价' : '') + (this.detailCreditObj.lockQuantity === 1 ? ' 锁量' : '')
      })
    }
    // 如果是详情的话  就有详情展示了
    if (this.$route.query.index) {
      this.showDetail = false
    }
    // 额度调整根据query.type判断
    if (this.$route.query.type) {
      this.adjustment = true
    }
    if (!this.$route.query.index) this.getcompactServiceList()
  },
  methods: {
    // 复选框点击
    changeIsChecked(item, index) {
      this.activeIndex = index
      if (item.isChecked) return
      this.$set(item, 'rateCycle', '')
      this.$set(item, 'rateValue', '')
      this.$refs.ruleForm.validateField(`agentInterestSchsArr.${this.activeIndex}.agentInterestSch`)
    },
    determineAudit() {
      // 根据当前的状态   赋值给要提交的状态
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.ruleForm.agentInterestSchs = this.ruleForm.agentInterestSchsArr.filter(item => item.rateValue)
          if (this.ruleForm.audit && this.detailCreditObj.creditStatus !== 1 && this.ruleForm.agentInterestSchs.length === 0) {
            this.$message.error('请填写计息方式')
            return
          }
          this.ruleForm.currentStatus = this.detailCreditObj.creditStatus
          this.ruleForm.lockPrice = this.ruleForm.lockPrice ? 1 : 0
          this.ruleForm.lockQuantity = this.ruleForm.lockQuantity ? 1 : 0
          this.ruleForm.auditResult = this.ruleForm.audit ? 1 : 0
          // 判断阶梯计费的时候必须填数据
          const flag = this.ruleForm.agentRateLadderConfigList.length === 0 ? false : this.ruleForm.agentRateLadderConfigList.every(item => item.base && Number(item.upperLimit) >= 0 && Number(item.lowerLimit) >= 0 && item.operFormula)
          if (this.ruleForm.audit && this.ruleForm.rateType === 2 && !flag) {
            this.$message.error('请认真填写完阶梯费率')
            return
          }
          this.ruleForm.creditTotalAmt = roundUpToInteger(Number(this.ruleForm.creditTotalAmtTwo || 0)) * 10000
          agentCreditInfoOperateUpdate(this.ruleForm, () => {
            this.$message.success('成功')
            this.$router.push({
              path: '/crmManagement/creditManagement'
            })
          }, () => {
            // 如果提交失败了  就把值还原会去
            this.ruleForm.lockPrice = this.ruleForm.lockPrice === 1
            this.ruleForm.lockQuantity = this.ruleForm.lockQuantity === 1
          })
        }
      })
    },
    addGoodsConfig() {
      this.ruleForm.agentRateLadderConfigList.push({})
    },
    // 输入上限值
    getUpperLimit(row, index) {
      if (Number(row.lowerLimit) < 0) {
        this.$message.error('请先填写金额下限')
        this.$set(row, 'upperLimit', '')
        return
      }
      if (parseInt(row.upperLimit) <= parseInt(row.lowerLimit)) {
        this.$message.error('金额上限要大于金额下限')
        this.$set(row, 'upperLimit', '')
        return
      }
      if ((index < this.ruleForm.agentRateLadderConfigList.length - 1) && (parseInt(this.ruleForm.agentRateLadderConfigList[index + 1].lowerLimit) < parseInt(row.upperLimit))) {
        this.$message.error('金额上限要小于等于下一个的金额下限')
        this.$set(row, 'upperLimit', '')
      }
    },
    // 输入下限值
    getLowerLimit(row, index) {
      if (parseInt(row.upperLimit) < parseInt(row.lowerLimit)) {
        this.$message.error('金额下限要小于金额上限')
        this.$set(row, 'lowerLimit', '')
        this.$set(row, 'upperLimit', '')
        return
      }
      if (index > 0 && (parseInt(this.ruleForm.agentRateLadderConfigList[index - 1].upperLimit) > parseInt(row.lowerLimit))) {
        this.$message.error('金额下限要大于等于上一个的金额上限')
        this.$set(row, 'lowerLimit', '')
        this.$set(row, 'upperLimit', '')
      }
    },
    // 获取代理采购协议
    getcompactServiceList() {
      compactServiceGetCompactByType(['01'], res => {
        this.contractTemplateList = [...res.data]
      })
    },
    // 获取代理采购协议名称
    getContractTemplateId(val) {
      const data = this.contractTemplateList.find(item => item.compactId === val)
      this.ruleForm.proxyContractTemplateName = data.compactName
    },
    // 额度调整
    limitAdjustment() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          agentCreditInfoAdjustmentQuota({
            creditTotalAmt: roundUpToInteger(Number(this.ruleForm.creditTotalAmtTwo || 0)) * 10000,
            id: this.ruleForm.id,
            validDate: this.ruleForm.validDate
          }, () => {
            this.$message.success('成功')
            this.$router.push({
              path: '/crmManagement/creditManagement'
            })
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.demo-ruleForm {
  padding: 32px 52px;
}
.hangOutDetails {
  .el-tabs {
    margin: 32px 0 16px;

    .detailsBox {
      transform: translateY(-20px);
    }
  }

  // .el-form-item__content {
  //   width: calc(100% - 140px);

  //   .el-select {
  //     width: 100%;
  //   }
  // }

  .lastOne {
    width: 100%;
  }

  .table-overspread {
    .el-form-item__content {
    width: 84%;
    }
  }

  .table {
    width: 100%;

    td {
      border: 1px solid #ededed;
      text-align: center !important;

      .icons {
        font-size: 26px;
        cursor: pointer;
      }

      .intervalValue {
        width: 48%;
        margin: 0 1%;
      }

      input {
        text-align: center;
      }
    }
  }

  .el-date-editor {
    width: 100%;
  }
  .specific-item{
    justify-content: flex-start;
  }
  .buyingExpenses {
    display: flex;
    align-items: center;
    position: relative;

      .unit{
        position: absolute;
        top: 50%;
        right: 52.5%;
        transform: translateY(-50%);
        font-size: 14px;
      }
    >span{
      width: 40px;
      text-align: right;
    }
    .interval {
      margin: 0 6px;
    }
  }
  .different-box{
    >div{
      flex: 0 0 50%;
    }
  }
  .settlementTitle{
    transform: translateX(-32px);
  }

  .input-unit {
    .el-form-item__content .el-input-group {
      display: flex;
    }

    .el-input-group__append {
      flex-shrink: 0;
      width: 60%;
      display: flex;
      padding: 0;

      .el-select {
        margin: 0;
      }
    }
  }

  .maxWidth {
    width: 100%;

    .el-form-item__content {
      width: calc(100% - 190px);
    }
  }
}
</style>
